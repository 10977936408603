import React, {useContext, useEffect} from "react";
import LogOffUtils from "@tp/thinkproject-logoff-utils";
import Auth from "@tp/thinkproject-auth";
import Loading from "./../Utils/Loading";
import {retrieve} from "./../Utils/Retrieve";
import {AppContext} from "../../contexts/AppProvider";

const Authorize = (props) => {
  const {
    iframe,
    profile,
    setProfile,
    isAuthorized,
    setIsAuthorized,
    setShowIdleTimeoutWarning,
    tpAuth,
    setTpAuth,
  } = useContext(AppContext);
  const {config} = props;

  let authWithOpts;

  useEffect(() => {
    if (!isAuthorized) {
      _authorizeUser();
    }
  }, [isAuthorized]);

  const _authorizeUser = () => {
    const {opIframeUrl} = config.sso.auth0Opts;
    const {backChannelLogout} = config.sso;
    let bcLogoutEnabled = backChannelLogout?.enabled;
    bcLogoutEnabled = bcLogoutEnabled === true || parseInt(bcLogoutEnabled) === 1;
    const auth0Opts = {
      onSuccess: (user) => {
        if (!iframe && bcLogoutEnabled) {
          _initBcSSOFF(user);
        }else if(!iframe){
          _addTimeoutListener();
        }
        return _getGumProfile();
      },
      ...(iframe || bcLogoutEnabled ? {opIframeUrl: ""} : {opIframeUrl}),
    };
    authWithOpts = Auth(config.sso.auth0Params, {
      ...config.sso.auth0Opts,
      ...auth0Opts,
    });
    setTpAuth(authWithOpts);
  };

  const _addTimeoutListener = () => {
    window.addEventListener("message", (ev) => {
      let data = "";
      try {
        data = JSON.parse(ev.data);
      } catch (e) {
        return false;
      }
      if (data.action === "timeout") {
        window.top.location = "/service/session-timeout";
      }
    });
  };

  const _initBcSSOFF = (user) => {
    const {backChannelLogout: {wssUrl}, idleSessionTimout} = config.sso;
    const accessToken = user.accessToken,
      subjectId = user.idTokenPayload.sid;

    if (!subjectId || !wssUrl || !accessToken) {
      console.log('initBackChannelLogout param missing');
      return;
    }

    const fns = {
      'gum-user-logged-in': (m) => console.log(m),
      'gum-user-logout': (m) => {
        console.log(m);
        authWithOpts.logout();
      },
      'client-status-unknown': (m) => console.log(m),
      'module-initialized': (m) => console.log(m),
      'module-disconnected': (m) => console.log(m),
      'module-error': (m) => console.log(m),
    }

    let logOffOpts = {
      accessToken,
      subjectId,
      wssUrl,
      callback: (msg) => {
        if (msg.type && fns.hasOwnProperty(msg.type)) {
          fns[msg.type](msg);
        }
      }
    };

    if (idleSessionTimout.enabled) {
      const idleSessionOpts = _getIdleSessionOpts(user, idleSessionTimout);
      logOffOpts = idleSessionOpts ? {...logOffOpts, idleSessionOpts} : logOffOpts;
    }

    new LogOffUtils(logOffOpts);
  }

  const _getIdleSessionOpts = (user, idleSessionTimout) => {
    const idleTimeoutKey = "https://namespace.thinkproject.com/session_timeout";
    let {timeOutSec, softLogoutUrl} = idleSessionTimout;
    timeOutSec = user.idTokenPayload[idleTimeoutKey] || timeOutSec;
    timeOutSec = !!timeOutSec && timeOutSec.toString() !== '0' ? parseInt(timeOutSec) : 0;

    if (timeOutSec) {
      let timeOutWarningSec;
      // if idleTimeOut greater than 5 min, then show warning 2 min before end
      // else show warning after 60% of timeOut
      if (timeOutSec >= 5 * 60) {
        timeOutWarningSec = timeOutSec - (2 * 60);
      } else {
        timeOutWarningSec = timeOutSec * 0.6;
      }
      return {
        timeOutSec,
        timeOutWarningSec,
        softLogoutUrl,
        onWarning: () => {
          setShowIdleTimeoutWarning(true);
        },
        onUserActivity: () => {
          setShowIdleTimeoutWarning(false);
        }
      }
    }
    return null;
  }

  const _getGumProfile = () => {
    if (!profile) {
      return _retrieveGumProfile();
    } else {
      setIsAuthorized(true);
    }
  };

  const _retrieveGumProfile = async () => {
    const authProfile =
      authWithOpts && authWithOpts.isAuthorized() ? authWithOpts.profile() : {};
    const {
      routes: {gum},
    } = config;
    const {accessToken} = authProfile;

    let {data} = await retrieve(accessToken, `${gum.self}/profile`);

    if (data && data.user) {
      let profile = data.user || {};
      profile = {...profile, accessToken};
      setProfile(profile);
      setIsAuthorized(true);
    }
  };

  const _renderChildren = (match) => {
    const {children, ...otherProps} = props;
    const {admin_status, admin_permission} = profile;
    const isSuperAdmin = admin_status === "super_admin";
    const isTpAdmin = isSuperAdmin || admin_status === "tp_admin";
    const isOrgaAdmin =
      isTpAdmin || (admin_status === "orga_admin" && admin_permission === "rw");
    return React.Children.map(children, (child) =>
      React.cloneElement(child, {
        ...otherProps,
        props,
        tpAuth: {...tpAuth, isSuperAdmin, isTpAdmin, isOrgaAdmin},
      })
    );
  };

  if (!profile && !isAuthorized) {
    return <Loading/>;
  }
  return _renderChildren();
};

export default Authorize;
