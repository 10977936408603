import {io, Socket} from 'socket.io-client';
import {
    ClientEventTypes,
    ClientToServerEvents,
    IdleSessionOpts,
    MessageTypes,
    Opts,
    ServerToClientEvents,
    WsOptions
} from './types';
import IdleSessionTimeout from "./idle-session-timeout";

/**
 * Creates an instance of LogOffUtils to handle single sign out
 * @constructor
 * @param {object} opts - Object containing accessToken, subjectId, wssUrl and a callback fn
 * @param {string} opts.accessToken - accessToken of logged-in user
 * @param {string} opts.subjectId - subjectId of logged-in user, it can be found in the idToken under the param name 'sid'
 * @param {string} opts.wssUrl - The URL of user-info-service
 * @param {function} opts.onSuccess - Function called to update client app about module, user, and connection state
 *
 * @param {object} wsOptions - Object containing socket.io-client params to create a connection with server
 * @param {number} [wsOptions.reconnectionDelay=5000] - Time to delay before attempting a reconnection
 * @param {string[]} [wsOptions.transports=['websocket', 'polling']] - Define transport types
 * @param {boolean} [wsOptions.tryAllTransports=true] - Try all transport types and find the suitable one
 * @param {number} [wsOptions.reconnectionAttempts=5] - Max number of reconnection attempts on connection error
 * @param {boolean} [wsOptions.withCredentials=true] - Use token in header or query to authorize client
 * @param {object} [wsOptions.extraHeaders] - Object containing authorization bearer to be used if transport polling is in used
 * @param {string} [wsOptions.extraHeaders.authorization=`Bearer ${opts.accessToken}`] - Authorization bearer
 * @param {object} [wsOptions.query] - Object containing authorization bearer to be used if transport type websocket is in used
 * @param {string} [wsOptions.query.subjectId=opts.subjectId] - subjectId of logged-in user, it can be found in the idToken under the param name 'sid'
 * @param {string} [wsOptions.query.authorization=`Bearer ${opts.accessToken}`] - Authorization bearer
 * @param {object} [wsOptions.IdleSession] - Object containing idle session timeout configuration for sof logout users after inactivity
 * @param {number} [wsOptions.IdleSession.timeOutWarningSec] - time in seconds to warn the user before soft logout
 * @param {number} [wsOptions.IdleSession.timeOutSec] - time in seconds to soft logout the user
 * @param {string} [wsOptions.IdleSession.softLogoutUrl] - Url to redirect user when timeOutSec has been reached (if fn onTimeoutReached is provided, redirect using this url will be ignored)
 * @param {function} [wsOptions.IdleSession.onWarning] - Function to be called when timeOutWarningSec has been reached (if fn is not provided, warning will be ignored)
 * @param {function} [wsOptions.IdleSession.onTimeoutReached] - Function to be called when timeOutSec has been reached (if this fn is provided no automatic soft logout using softLogoutUrl will be used)
 */

export default class LogOffUtils {
    private readonly wsOptions: WsOptions;
    private socket: Socket<ServerToClientEvents, ClientToServerEvents>;
    private readonly opts: Opts;
    private idleSessionTimeout: IdleSessionTimeout;
    private isConnectedAndLoggedIn: boolean = false;
    private idleSessionTimeOutIsInitialized: boolean = false;
    private pageIsActive: boolean = true;
    private readonly processingTimeIntervalMs: number = 10 * 1000;

    constructor(opts: Opts) {
        if (!opts.accessToken || !opts.subjectId || !opts.wssUrl) {
            opts.callback({
                type: MessageTypes.ModuleError,
                reason: 'missing one or more options [accessToken, subjectId, wssUrl]',
            });
            return;
        }

        let query = {
            subjectId: opts.subjectId,
            authorization: `Bearer ${opts.accessToken}`,
            timeOutSec: 0,
            timeOutWarningSec: 0
        };
        if (opts.idleSessionOpts) {
            const {timeOutSec, timeOutWarningSec} = opts.idleSessionOpts as IdleSessionOpts;
            query = {...query, timeOutSec, timeOutWarningSec};
        }

        this.wsOptions = {
            reconnectionDelay: 5000,
            transports: ['websocket', 'polling'],
            tryAllTransports: true,
            reconnectionAttempts: 5,
            withCredentials: true,
            extraHeaders: {
                authorization: `Bearer ${opts.accessToken}`
            },
            query
        }
        this.opts = opts;
        this.initLogOffUtils();
    }

    private initLogOffUtils = (): void => {
        this.socket = io(this.opts.wssUrl, this.wsOptions);
        // disconnect/connect client depending on page visibility
        this.bindPageEvents();
        this.opts.callback({
            type: MessageTypes.ModuleInitialized,
            reason: 'module logOff initialized'
        });

        this.socket.on('connect', () => {
            this.opts.callback({
                type: MessageTypes.ModuleConnect,
                reason: 'initialized and connected'
            });
        });

        this.socket.on('connect_error', (error) => {
            this.isConnectedAndLoggedIn = false;
            this.opts.callback({
                type: MessageTypes.ModuleError,
                reason: error?.message || 'connection error'
            });
        });

        this.socket.on('disconnect', (reason) => {
            this.isConnectedAndLoggedIn = false;
            this.opts.callback({
                type: MessageTypes.ModuleDisconnect,
                reason: reason || 'socket disconnection'
            });
        });

        this.socket.on(ClientEventTypes.Message, (msg) => {
            this.opts.callback(msg);
            if (msg.type === MessageTypes.UserLoggedIn) {
                this.isConnectedAndLoggedIn = true;
                if (this.opts.idleSessionOpts && !this.idleSessionTimeOutIsInitialized) {
                    this.initIdleSessionTimeout();
                }
            }
        });

        this.socket.on(ClientEventTypes.LogOut, (msg) => {
            this.opts.callback(msg);
        });

        this.socket.on(ClientEventTypes.Error, (msg) => {
            this.opts.callback(msg);
        });
        // reconnect or notify for activity if needed every processingTimeIntervalMs
        setInterval(() => this.processActivityAndProofConnection(), this.processingTimeIntervalMs);
    }

    private processActivityAndProofConnection = (): void => {
        // we should reconnect only if the socket is disconnected
        if (!this.isConnectedAndLoggedIn) {
            this.reconnectSocket();
        }
        if (this.idleSessionTimeOutIsInitialized) {
            this.idleSessionTimeout.shouldEmitForActivity();
        }
    }

    private initIdleSessionTimeout = (): void => {
        const {idleSessionOpts, subjectId} = this.opts;
        const {onTimeoutReached, softLogoutUrl} = idleSessionOpts;
        if (!(onTimeoutReached && typeof onTimeoutReached === 'function') && !softLogoutUrl) {
            this.opts.callback({
                type: MessageTypes.ModuleError,
                reason: 'No onTimeoutReached function or softLogoutUrl was specified'
            });
        } else {
            this.idleSessionTimeout = new IdleSessionTimeout(idleSessionOpts, subjectId, this.socket);
            this.idleSessionTimeOutIsInitialized = true;
            this.opts.callback({
                type: MessageTypes.ModuleInitialized,
                reason: 'module idleSessionTimeout initialized'
            });
        }
    }

    private disconnectSocket = (): void => {
        this.socket.disconnect();
    }

    private reconnectSocket = (): void => {
        this.socket.connect();
    }

    private bindPageEvents = (): void => {
        // disconnect from server before tab closing or page reload
        window.addEventListener('beforeunload', () => {
            this.pageIsActive = false;
            this.disconnectSocket();
        });
    }
}
