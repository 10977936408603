import {Socket} from 'socket.io-client';
import {ClientEventTypes, ClientToServerEvents, IdleSessionOpts, MessageTypes, ServerToClientEvents} from './types';

/**
 * Creates an instance of IdleSessionTimeout to handle idle-session-timeout
 * @constructor
 * @param {object} [wsOptions.IdleSession] - Object containing idle session timeout configuration for sof logout users after inactivity
 * @param {number} [wsOptions.IdleSession.timeOutWarningSec] - time in seconds to warn the user before soft logout
 * @param {number} [wsOptions.IdleSession.timeOutSec] - time in seconds to soft logout the user
 * @param {string} [wsOptions.IdleSession.softLogoutUrl] - Url to redirect user when timeOutSec has been reached (if fn onTimeoutReached is provided, redirect using this url will be ignored)
 * @param {function} [wsOptions.IdleSession.onWarning] - Function to be called when timeOutWarningSec has been reached (if fn is not provided, warning will be ignored)
 * @param {function} [wsOptions.IdleSession.onTimeoutReached] - Function to be called when timeOutSec has been reached (if this fn is provided no automatic soft logout using softLogoutUrl will be used)
 * @param {string} opts.subjectId - subjectId of logged-in user, it can be found in the idToken under the param name 'sid'
 * @param {Socket} socket - The connected socket.io-client
 */

export default class IdleSessionTimeout {
    private socket: Socket<ServerToClientEvents, ClientToServerEvents>;
    private subjectId: string;
    private readonly idleSessionOpts: IdleSessionOpts;
    private EVENT_LISTENERS = [
        'mousemove',
        'mousedown',
        'mouseover',
        'mouseup',
        'keypress',
        'mousewheel',
        'click',
        'touchstart',
        'touchmove',
        'touchend',
    ];

    private warningReceived: boolean = false;
    private activityRegistered: boolean = true;

    constructor(idleSessionOpts: IdleSessionOpts, subjectId: string, socket: Socket) {
        this.idleSessionOpts = idleSessionOpts;
        this.socket = socket;
        this.initIdleSessionTimeout();
        this.shouldEmitForActivity();
    }

    private initIdleSessionTimeout = (): void => {
        this.socket.on(ClientEventTypes.IdleSession, (msg) => {
            if (msg.type === MessageTypes.UserIdleSessionWarning) {
                const {onWarning} = this.idleSessionOpts;
                this.warningReceived = true;
                if (onWarning && typeof onWarning === 'function') {
                    onWarning();
                }
            }

            if (msg.type === MessageTypes.UserIdleSessionEnded) {
                this.socket.disconnect();
                this.softLogout();
            }

            if (msg.type === MessageTypes.UserIdleSessionActive) {
                const {onUserActivity} = this.idleSessionOpts;
                if (onUserActivity && typeof onUserActivity === 'function') {
                    onUserActivity();
                }
            }
        });

        this.EVENT_LISTENERS.forEach((listener) => {
            document.addEventListener(listener, (e) => {
                this.activityRegistered = true;
            }, true);
        });

    }

    public shouldEmitForActivity = (): void => {
        if (this.activityRegistered) {
            this.warningReceived = false;
            this.activityRegistered = false;
            this.socket.emit(ClientEventTypes.IdleSession, {
                type: MessageTypes.UserIdleSessionActive,
                subjectId: this.subjectId
            });
        }
    }

    private softLogout = (): void => {
        const {onTimeoutReached, softLogoutUrl} = this.idleSessionOpts;
        if (onTimeoutReached && typeof onTimeoutReached === 'function') {
            onTimeoutReached();
        } else if (softLogoutUrl) {
            window.location.href = softLogoutUrl;
        }
    }
}
