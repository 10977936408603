import React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import QueryString from "query-string";
import {AppContext} from "../../../contexts/AppProvider";

class ErrorNotifications extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    const {
      location: {search},
    } = props;
    this.state = {
      queries: QueryString.parse(search) || {},
      notifications: [],
      idleTimeoutWarningActive: false
    };
    this.RouteMapper = {
      idle_session_timeout: "service/session-timeout",
    };
  }

  componentDidUpdate() {
    const {_t} = this.props;
    const {showIdleTimeoutWarning} = this.context;
    const {notifications, idleTimeoutWarningActive} = this.state;
    if (showIdleTimeoutWarning && !idleTimeoutWarningActive) {
      this.setState({
        notifications: [...notifications, {
          type: 'error',
          title: _t('portal.idle.timeout.warning.header'),
          msg: _t('portal.idle.timeout.warning'),
        }],
        idleTimeoutWarningActive: true
      });
      this._scrollToTop();
    } else if (!showIdleTimeoutWarning && idleTimeoutWarningActive) {
      this.setState({
        notifications: notifications.filter((n) => n.msg !== _t('portal.idle.timeout.warning')),
        idleTimeoutWarningActive: false
      })
    }
  }

  componentDidMount() {
    this._checkTpcdeReasonsOnload();
    this.props.onRef(this);
  }

  _scrollToTop() {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }

  _getSessionTimeoutRoute = (reason, isSatellite) => {
    const PARAM_IS_SATELLITE = "?isSatellite=1";
    let route = this.RouteMapper[reason];
    if (isSatellite) {
      route += PARAM_IS_SATELLITE;
    }
    return route;
  };

  _checkTpcdeReasonsOnload() {
    const {_t} = this.props;
    const {
      queries: {reason, isSatellite},
      notifications,
    } = this.state;
    if (this.RouteMapper[reason]) {
      // TPCDE-21595: to avoid opIframe getting added to the session-tieout page, we use location.href instead of react-router Navigate
      location.href = this._getSessionTimeoutRoute(reason, isSatellite);
      return;
    }
    if (reason) {
      const key = `portal.reason.${reason.toLowerCase()}`;
      const msg = _t(key);
      if (key !== msg) {
        this.setState({
          notifications: [...notifications, {type: "error", msg}],
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  tpOnComponentsDidChange(props) {
    // call this notification in your comp like this
    // this.props.tpComponentsDidChange('notify', {
    //  type: 'error || info || warning || success',
    //  msg: 'This a notification message!' || '<p>some text</p>',
    //  msgType: 'html' // if msg contains HTML else ignore this parameter
    // });
    const {notify} = props;
    const {notifications} = this.state;
    if (
      notify &&
      typeof notify === "object" &&
      Object.keys(notify).length !== 0
    ) {
      this.setState({notifications: [...notifications, notify]});
    }
  }

  _action(i, onClose) {
    return (
      <>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => {
            onClose && typeof onClose === "function" && onClose();
            this._handleClose(i);
          }}
        >
          <CloseIcon fontSize="small"/>
        </IconButton>
      </>
    );
  }

  _handleClose(i) {
    const {notifications} = this.state;
    this.setState({
      notifications: notifications.filter((n, index) => index !== i),
    });
  }

  _renderTitle(title) {
    return (<AlertTitle>{title}</AlertTitle>);
  }

  _renderAlert(notification, i) {
    // types = error || info || warning || success
    const {type, msg, onClose, title} = notification;
    return (
      <Alert
        sx={{mb: "8px"}}
        severity={type}
        data-index={i}
        key={i}
        action={this._action(i, onClose)}
      >
        {title ? this._renderTitle(title) : null}
        {msg}
      </Alert>
    );
  }

  render() {
    const {notifications} = this.state;
    const {iframe, servicePage} = this.context;
    if (notifications.length && !iframe && !servicePage) {
      return (
        <div className={"portal-notifications"}>
          {notifications.map((notification, i) => {
            return this._renderAlert(notification, i);
          })}
        </div>
      );
    }
    return null;
  }
}

export default ErrorNotifications;
