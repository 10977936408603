interface WsOptions {
    reconnectionDelay: number;
    transports: string[];
    tryAllTransports: boolean;
    reconnectionAttempts: number;
    withCredentials: boolean;
    extraHeaders: {
        authorization: string;
    };
    query: {
        subjectId: string;
        authorization: string;
        timeOutSec?: number;
        timeOutWarningSec?: number;
    }
}

enum ClientEventTypes {
    Message = 'ws-message',
    LogOut = 'ws-logout',
    Error = 'ws-error',
    IdleSession = 'ws-idle-session'
}

enum MessageTypes {
    UserLoggedOut = 'gum-user-logout',
    UserLoggedIn = 'gum-user-logged-in',
    UserStatusUnknown = 'client-status-unknown',
    ModuleInitialized = 'module-initialized',
    ModuleConnect = 'module-connected',
    ModuleDisconnect = 'module-disconnected',
    ModuleError = 'module-error',
    UserIdleSessionActive = 'user-idle-session-active',
    UserIdleSessionEnded = 'user-idle-session-ended',
    UserIdleSessionWarning = 'user-idle-session-warning',
}

interface Msg {
    type: MessageTypes;
    reason?: string;
    subjectId?: string;
    subject?: string;
}

interface ServerToClientEvents {
    'ws-message': (a: Msg, callback: (e: number) => void) => void;
    'ws-logout': (a: Msg, callback: (e: number) => void) => void;
    'ws-error': (a: Msg, callback: (e: number) => void) => void;
    'ws-idle-session': (a: Msg, callback: (e: number) => void) => void;
}

interface ClientToServerEvents {
    'ws-message': (a: Msg) => void;
    'ws-logout': (a: Msg) => void;
    'ws-error': (a: Msg) => void;
    'ws-idle-session': (a: Msg) => void;
}

interface IdleSessionOpts {
    timeOutSec: number;
    timeOutWarningSec: number;
    softLogoutUrl?: string;
    onUserActivity?: () => void;
    onWarning?: () => void;
    onTimeoutReached?: () => void;
}

interface Opts {
    accessToken: string;
    subjectId: string;
    wssUrl: string;
    idleSessionOpts?: IdleSessionOpts;
    callback: (a: Msg) => void;
}


export {ClientEventTypes, MessageTypes};
export type {WsOptions, Msg, ServerToClientEvents, ClientToServerEvents, Opts, IdleSessionOpts};